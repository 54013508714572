<template>
   <v-container style="max-width:860px;">
      <v-row>
         <v-col cols="12">
            <v-card tile flat>
               <v-card-title 
                  class="py-1 px-4 pl-2"
                  style="background-color:transparent;color:#0D47A1;border-bottom: 2px solid #0D47A1;">
                  <v-icon color="#0D47A1" class="mr-1" small>mdi-star</v-icon>
                  Quản lý quyền truy cập
               </v-card-title>
            </v-card>
         </v-col>

         <v-col cols="12"
            class="pt-3 pb-6"
            v-for="(role, index) in sysRoles" :key="index">
            <v-card flat style="border: 1px solid #CCCCCC;">
               <v-card-title 
                  class="py-1 px-4 pl-2 pr-2"
                  style="background-color:#BBDEFBCC;color:#0D47A1;font-size:17px;">
                  <v-icon color="#0D47A1"> mdi-menu-right </v-icon>
                  {{role.name}}
                  <v-spacer/>
                  <v-btn color="#0D47A1" 
                     height="30px"
                     dark
                     @click="startPickUserForRole(role)"
                     class="text-none px-2">
                     <v-icon small>mdi-plus</v-icon>
                     Thêm người
                  </v-btn>
               </v-card-title>

               <v-card-text class="py-1 px-4">
                  <div v-if="role.users && role.users.length<=0"
                     style="color:#888888;">
                     <i>Chưa có nhân viên nào</i>
                  </div>
                  <div v-if="role.users && role.users.length>0">
                     <div v-for="(user, uIndex) in role.users" :key="uIndex"
                        style="line-height:28px;border-bottom: 1px solid #F0F0F0;">
                        <v-btn icon small
                           color="red"
                           class="ma-0 mr-2"
                           @click="removeUserFromRole(role, user)">
                           <v-icon style="font-size:18px;" >mdi-delete-outline</v-icon>
                        </v-btn>
                        <b>{{user.fullname}}</b>
                        <template v-if="user.position"> - <span style="font-size:12px;">{{user.position}}</span></template>
                     </div>
                  </div>
               </v-card-text>
            </v-card>
         </v-col>

         <v-dialog v-model="dialogUser"
            max-width="460px">
            <select-user 
               @closeMe="dialogUser=false"
               @onUserSelected="onOneUserPick"/>
         </v-dialog>


      </v-row>
   </v-container>
</template>

<script>
export default {
   data () {
      return {
         roles: ['admin'],
         sysRoles: [],
         dialogUser: false,
         activeRole: null
      }
   },
   methods: {
      async fetchRoles () {
         try {
            let res = await this.axios.get(this.$root.apiUser + `/roles?customer_id=${this.$root.apiUserCustomId}&enabled=1&withUsers=1`)
            console.log('All Roles')
            console.log(res.data)
            if (res.data.status == "OK"){
               this.sysRoles = res.data.content
            }
            // console.log(this.roles)
         } catch (err) {
            console.log(err)
         }
         
      },
      startPickUserForRole (role) {
         this.activeRole = role
         this.dialogUser = true
      },
      async onOneUserPick (pickedUser) {
         this.dialogUser = false
         // console.log(pickedUser)
         // console.log(this.activeRole)
         if(!this.activeRole.users) this.activeRole.users = []
         this.activeRole.users.push(pickedUser)
         await this.saveRole(this.activeRole)
      },
      async removeUserFromRole (role, user) {
         role.users = role.users.filter(u => u._id != user._id)
         await this.saveRole(role)
      },
      async saveRole (role) {
         try {
            let res = await this.axios.put(
               this.$root.apiUser + `/roles/${role._id}`,
               {users: role.users.map(u => u._id)})
            if (res.data.status == "OK"){
               console.log("Add done")
            } else {
               alert(res.data.status)
            }
         } catch (err) {
            console.log(err)
         }
      }
   },
   mounted() {
      this.$nextTick(async () => {
         await this.fetchRoles()
      })
   },
}
</script>

<style>
   
</style>